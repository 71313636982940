export const seo = {
  url: 'global',
  title: {
    en: 'International Transport | Transport in Countries | Transport Europe',
    es: 'Transporte Internacional | Transporte en Países | Transporte Europa',
    ro: 'Transport internațional | Transport în Țări | Transport Europa',
  },
  desc: {
    en: 'We provide international transport to all countries in Europe. We organize transport in the FTL and LTL model. Grow your business with us.',
    es: 'Realizamos transporte internacional a todos los países de Europa. Organizamos el transporte en el modelo FTL y LTL. Haz crecer tu negocio con nosotros.',
    ro: 'Oferim transport internațional în toate țările din Europa. Organizam transport in modelul FTL si LTL. Crește-ți afacerea cu noi.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'europe',
    'international',
    'global',
  ],
}

export const intro = {
  title: {
    en: 'International Transport',
    es: 'Transporte Internacional',
    ro: 'Transport Internațional',
  },
  desc: {
    en: 'We provide international transport to all countries in Europe. We organize transport in the FTL and LTL model. Grow your business with us.',
    es: 'Realizamos transporte internacional a todos los países de Europa. Organizamos el transporte en el modelo FTL y LTL. Haz crecer tu negocio con nosotros.',
    ro: 'Oferim transport internațional în toate țările din Europa. Organizam transport in modelul FTL si LTL. Crește-ți afacerea cu noi.',
  },
  button: {
    text: {
      en: 'Read more',
      es: 'Leer más',
      ro: 'Citește mai mult',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        en: 'International Transport - choose an experienced logistics partner',
        es: 'Transporte internacional: elija un socio logístico experimentado',
        ro: 'Transport internațional - alegeți un partener logistic cu experiență',
      },
      texts: [
        {
          en: '<span><strong>International transport</strong> covers issues related to forwarding and logistic processes developed to a greater extent than domestic transport. This type is characterized by a very <strong>efficient cargo delivery network</strong> from pick-up to destination. We focus on choosing the right transport service, organizing <strong>safe route</strong> transport and <strong>punctual deliveries</strong>.</span>',
          es: '<span><strong>Transporte internacional</strong> cubre temas relacionados con el envío y los procesos logísticos desarrollados en mayor medida que el transporte nacional. Este tipo se caracteriza por una red de entrega de carga muy <strong> eficiente </strong> desde la recogida hasta el destino. Nos enfocamos en elegir el servicio de transporte adecuado, organizando <strong>rutas seguras</strong> de transporte y <strong> entregas puntuales</strong>.</span>',
          ro: '<span><strong>Transportul internațional</strong> acoperă aspecte legate de procesele de expediere și logistică dezvoltate într-o măsură mai mare decât transportul intern. Acest tip se caracterizează printr-o <strong>rețea de livrare a mărfurilor foarte eficientă</strong> de la preluare până la destinație. Ne concentrăm pe alegerea serviciului de transport potrivit, pe organizarea de <strong>rute sigure</strong> de transport și <strong>livrări punctuale</strong>.</span>',
        },
      ],
    },
    {
      headline: {
        en: 'Intermodal Transport',
        es: 'Transporte Intermodal',
        ro: 'Transport Intermodal',
      },
      texts: [
        {
          en: '<span> From 2020, we offer our clients <strong> ecological intermodal transport </strong>, which allows you to reduce the cost of travel on specific routes and directions. It is a transport in which we use <strong> several forms of transport in one unit </strong> along the entire route. In intermodal transport, it is possible to prepare <strong> a long-term contract with a price guarantee </strong>. This solution is dedicated to companies wishing to obtain a guarantee of stability of transport costs throughout the year. Undoubtedly, the advantages of intermodal transport are <strong> cargo safety </strong> and <strong> optimization of transport times </strong>. </span>',
          es: '<span> A partir de 2020, ofrecemos a nuestros clientes <strong> transporte intermodal ecológico </strong>, que le permite reducir el costo de los viajes en rutas y direcciones específicas. Es un transporte en el que utilizamos <strong> varios medios de transporte en una unidad </strong> a lo largo de todo el recorrido. En el transporte intermodal, es posible preparar <strong>un contrato a largo plazo con garantía de precio</strong>. Esta solución está dedicada a las empresas que desean obtener una garantía de estabilidad de los costes de transporte durante todo el año. Sin duda, las ventajas del transporte intermodal son la <strong> seguridad de la carga </strong> y la <strong> optimización de los tiempos de transporte </strong>. </span>',
          ro: '<span> Din 2020, oferim clienților noștri <strong> transport intermodal ecologic </strong>, care vă permite să reduceți costul călătoriei pe anumite rute și direcții. Este un transport în care folosim <strong>mai multe forme de transport într-o singură unitate</strong> de-a lungul întregului traseu. În transportul intermodal, este posibil să se întocmească <strong>un contract pe termen lung cu garanție de preț</strong>. Această soluție este dedicată companiilor care doresc să obțină o garanție de stabilitate a costurilor de transport pe tot parcursul anului. Fără îndoială, avantajele transportului intermodal sunt <strong> siguranța mărfurilor </strong> și <strong> optimizarea timpilor de transport </strong>. </span>',
        },
      ],
    },
    {
      headline: {
        en: 'What types of international transport we offer?',
        es: '¿Qué tipos de transporte internacional ofrecemos?',
        ro: 'Ce tipuri de transport internațional oferim?',
      },
      texts: [
        {
          en: `
          <div>
           <p> Omida Logistics is a transport company with a wide range of transport products and services, dedicated to individual branches of the economy. We offer various forms of transport, such as: </p>
             <ul>
               <li><a href="/ftl-transport/">FTL Transport</a></li>
               <li><a href="/ltl-transport/">LTL Transport</a></li>
               <li><a href="/intermodal/">Intermodal Transport</a> as part of the direction - Germany, Belgium, the Netherlands</li>
             </ul>
           </div>
          `,
          es: `
          <div>
           <p> Omida Logistics es una empresa de transporte con una amplia gama de productos y servicios de transporte, dedicada a ramas individuales de la economía. Ofrecemos diversas formas de transporte, tales como: </p>
             <ul>
               <li><a href="/ftl-transport/">Transporte FTL</a></li>
               <li><a href="/ltl-transport/">Transport LTL</a></li>
               <li><a href="/intermodal/">Transporte Intermodal</a> como parte de la dirección - Alemania, Bélgica, Países Bajos </li>
             </ul>
           </div>
          `,
          ro: `
          <div>
          <p> Omida Logistics este o companie de transport cu o gamă largă de produse și servicii de transport, dedicată unor ramuri individuale ale economiei. Oferim diverse forme de transport, tales as: </p>
            <ul>
              <li><a href="/ftl-transport/">Transporte FTL</a></li>
              <li><a href="/ltl-transport/">Transport LTL</a></li>
              <li><a href="/intermodal/">Transporte Intermodal</a> ca parte de la direcția - Germania, Belgia, Țările de jos </li>
            </ul>
          </div>
          `,
        },
        {
          en: `
          <div>
          All types of transport we offer nationally and internationally can be found in the <a href="/services/"> Transportation Services tab </a>. We encourage you to choose the appropriate transport service, matched to <strong> individual preferences </strong>. To find out more about the organization and processes involved in planning a trip to another country - <a href="/quote/"> quote your transport </a>.
          </div>
          `,
          es: `
          <div>
           Todos los tipos de transporte que ofrecemos a nivel nacional e internacional se pueden encontrar en la pestaña <a href="/services/">Servicios de transporte</a>. Le animamos a elegir el servicio de transporte adecuado, que coincida con las <strong> preferencias individuales </strong>. Para obtener más información sobre la organización y los procesos involucrados en la planificación de un viaje a otro país, <a href="/quote/"> cotice su transporte </a>.
           </div>
          `,
          ro: `
          <div>
          Toate tipurile de transport care ne oferă la nivel național și internațional se pot găsi în fila <a href="/services/">Servicii de transport</a>. Vă încurajăm să alegeți serviciul de transport adecvat, care să corespundă <strong>preferințelor dumneavoastră individuale</strong>. Pentru mai multe informații despre organizarea și procesele implicate în planificarea unei călătorii în altă țară, citați transportul.
          </div>
          `,
        },
      ],
    },
  ],
}

export const buttonBack = {
  text: {
    en: 'Back to transport',
    es: 'Volver al transporte',
    ro: 'Înapoi la transport',
  },
  link: '/transport/',
}
